window.addEventListener('DOMContentLoaded', () => {
    let block_latest_blogs = document.querySelector('.home-latest-posts-group .wp-block-uagb-post-grid');
    let currentPage = 4; 
    const postsPerPage = 4; 
    const dominio = document.querySelector('body').baseURI;

    if (block_latest_blogs) {
        const bootstrapLink = document.createElement('link');
        bootstrapLink.rel = 'stylesheet';
        bootstrapLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0/css/bootstrap.min.css';
        document.head.appendChild(bootstrapLink);

        block_latest_blogs.innerHTML += `
            <div class="btn_container">
                <a href="#" id="btn_more_posts" class="dl-btn primary-button">Read More</a>
                <a href="/blog" class="dl-btn secondary-button">All Posts</a>
            </div>
        `;

        const btn_more_posts = document.querySelector('#btn_more_posts');
        const btn_container = document.querySelector('.home-latest-posts-group  .btn_container');


        const loadPosts = async () => {
            try {
                // Mostrar el spinner mientras se cargan los posts
                btn_more_posts.innerHTML = `
                    <div class="spinner-border text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                `;

                const response = await fetch(`${dominio}wp-json/wp/v2/posts?per_page=${postsPerPage}&page=${currentPage}`);
                if (!response.ok) throw new Error('Error al cargar los posts');
                
                const posts = await response.json();

                posts.forEach(post => {
                    console.log(post);
                    const postElement = document.createElement('article');
                    postElement.classList.add('uagb-post__inner-wrap');
                    postElement.innerHTML = `
                    <div class="uagb-post__image">
                    <a href="${post.link}" target="_self" rel="bookmark noopener noreferrer" class="uagb-image-ratio-2-3">
                        <img decoding="async" width="1920" height="2560" src="${post.mv.thumbnail_uri}" class="attachment-full size-full lazyload" alt="${post.title.rendered}">
                    </a>
                    </div>
                    <h3 class="uagb-post__title uagb-post__text">
                        <a href="${post.url}" target="_self" rel="bookmark noopener noreferrer">${post.title.rendered}</a>
                    </h3>
                    <div class="uagb-post__text uagb-post-grid-byline">
                    
                    </div>
                    `;
                    block_latest_blogs.insertBefore(postElement, btn_container);
                });

                currentPage++;

                btn_more_posts.innerHTML = 'Read More';

                // Deshabilitar el boton si ya no hay mas posts
                if (posts.length < postsPerPage) {
                    btn_more_posts.style.display = 'none';
                }

            } catch (error) {
                console.error(error);
                btn_more_posts.innerHTML = 'Read More'; 
            }
        };

        btn_more_posts.addEventListener('click', (e) => {
            e.preventDefault();
            loadPosts();
        });
    }
});
